.header-container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;

    >div.header-content {
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        border-bottom: 1px hsl(0, 0%, 89%) solid;
        width: calc(100vw);

        background-color: white;

        .logo {
            display: flex;
            align-items: center;
            gap: 6px;
            color: hsl(0, 0%, 29%);

            span {
                font-size: 22px;
                padding-top: 2px;

                &:hover {
                    cursor: pointer;
                }
            }
        }


        form.search-container {
            align-items: center;
            gap: 10px;
            position: relative;


            .filter-container-outer {
                position: absolute;
                top: 100%;
                width: 100%;
                padding-top: 6px;

                .filter-container {
                    height: 100%;
                    background-color: hsla(0, 0%, 100%);
                    box-shadow: 0 0 12px hsl(0, 0%, 0%, 0.02);
                    border-radius: 24px;
                    border: 1px hsl(0, 0%, 89%) solid;
                    padding: 24px;
                    width: 100%;

                    .top {
                        justify-content: space-between;
                        margin-bottom: 16px;
                        align-items: center;
                        color: hsl(0, 0%, 45%);
                        padding-bottom: 16px;
                        border-bottom: 0.5px solid hsl(0, 0%, 90%);

                        button {
                            padding: 6px 12px;
                            margin: 0;
                            font-size: 13px;
                            // background-color: hsl(0, 0%, 96%);
                            border: 1px solid hsl(0, 0%, 90%);
                            background-color: transparent;
                            color: hsl(0, 0%, 60%);

                            &:last-child {
                                margin-left: 8px;
                            }

                            &:hover {
                                border-color: hsl(0, 0%, 65%);
                                color: hsl(0, 0%, 40%);
                            }
                        }

                    }

                    .filter-form {
                        display: grid;
                        width: 100%;
                        row-gap: 8px;
                        grid-template-columns: 1fr 1fr auto;
                    }
                }
            }

            &:hover {
                .filter-container-outer {
                    display: block;

                    .input-container {

                        input {
                            box-shadow: 0 0 12px hsl(0, 0%, 0%, 0.02);
                        }
                    }
                }


            }

            .input-container {

                span {
                    position: absolute;
                    top: 6px;
                    left: 10px;
                    font-weight: 200;
                    color: hsl(0, 0%, 80%);
                }

                input {
                    padding: 8px 8px 8px 40px;
                    border-radius: 50px;
                    width: 40vw;
                    border: 1px hsl(0, 0%, 89%) solid;
                    background-color: white;
                    font-size: 14px;
                }

                button {
                    margin-left: 6px;
                }
            }



            button {
                font-size: 14px;
                color: hsl(0, 0%, 45%);
                background-color: hsl(0, 0%, 94%);
                padding: 8px 24px;
                border-radius: 50px;

                &:hover {
                    cursor: pointer;
                    background-color: hsl(0, 0%, 90%);
                    // box-shadow: 0 0 10px hsla(0, 0%, 70%, 0.5);
                }
            }

        }

        .upload-user-container {
            align-items: center;
            gap: 15px;

            .upload-btn {
                font-size: 14px;
                color: hsl(0, 0%, 20%);
                margin-left: 10px;
                background-color: hsl(0, 0%, 85%);
                padding: 8px 24px;
                border-radius: 50px;

                &:hover {
                    cursor: pointer;
                    background-color: hsl(0, 0%, 90%);
                    // box-shadow: 0 0 10px hsla(0, 0%, 70%, 0.5);
                }
            }

            .user-container {
                position: relative;

                .avatar-container {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    // text-align: center;

                    .avatar {
                        height: 35px;
                        border-radius: 50px;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    p {
                        font-size: 16px;
                        font-weight: 500;
                        color: hsl(0, 0%, 30%);
                    }
                }

                .user-actions-container {
                    position: absolute;
                    display: none;
                    background-color: white;
                    border-radius: 12px;
                    border: 1px solid rgb(218, 218, 218);
                    padding: 18px;
                    right: 0;
                    color: rgb(91, 91, 91);

                    .delete,
                    .logout {
                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .delete {
                        margin-top: 36px;
                        padding-top: 18px;
                        border-top: 0.5px solid rgba(0, 0, 0, 0.4);
                        color: rgb(221, 17, 17);

                    }

                    .logout {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .icon {
                            font-size: 18px;
                        }
                    }
                }

                &:hover {
                    .user-actions-container {
                        display: block;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 430px) {
    .header-container {
        width: 100vw;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);

        .logo {

            grid-column: 1 / 2;
            grid-row: 1 / 2;

            a {
                margin-left: 6px;
            }
        }

        .upload-user-container {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }

        .search-icon {
            color: hsl(0, 0%, 30%);
        }

        form.search-container {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100vw;
            padding: 12px 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: 0 6px 6px rgba(0, 0, 0, 0.05);
            background-color: white;
            grid-column: 1 / 3;
            grid-row: 2 / 3;

            .filter-container-outer {
                position: relative;

                .filter-container {
                    border: none;
                    box-shadow: none;

                    .top {
                        // flex-direction: column;
                        // align-items: flex-start;

                        .button-container {
                            display: flex;
                            align-items: center;
                            white-space: nowrap;
                            gap: 0px;
                        }
                    }
                }
            }

            .input-container {
                flex-grow: 1;

                .search {
                    display: flex;
                    position: relative;
                }

                .filter-container {
                    padding: 24px 0;
                }

                input {
                    width: 100%;
                    flex-grow: 1;
                }
            }
        }
    }
}