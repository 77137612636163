.footer-container {
    max-height: 500px;
    background-color: white;
    border-top: 1px solid hsl(0, 0%, 90%);
    flex-direction: column;
    align-items: center;
    padding: 100px 0 20px;
    gap: 15px;
    width: 100%;

    &.challenge {
        background-color: rgb(13, 18, 36);
        color: rgba(255, 255, 255, 0.7);
        border-top: none;

        .social-media {
            img {
                filter: invert(1);
            }
        }
    }

    &.placeholder {
        padding-left: 261px;
    }

    .item {
        display: flex;
        align-items: center;

        &::after {
            content: "";
            margin-left: 7.5px;
            display: inline-block;
            height: 60%;
            width: 1px;
            background-color: hsl(0, 0%, 70%);
        }
    }

    .logo {
        margin-bottom: 40px;
    }

    .section {
        gap: 7.5px;
    }

    .social-media {
        gap: 5px;
        margin-bottom: 30px;

        img {
            width: 25px;
            opacity: 0.8;

            &:hover {
                cursor: pointer;
            }
        }

    }

    .copyright {
        margin-top: 80px;
        font-size: 13px;
        opacity: 0.4;
    }

}

@media screen and (max-width: 430px) {
    .footer-container {
        max-height: 1000px;

        .section {
            flex-direction: column;
            align-items: center;
            margin-bottom: 16px;
        }

        .item {

            &::after {
                content: "";
                margin-left: 0px;
                height: 0;
                width: 0px;
            }
        }
    }
}