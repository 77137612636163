.pdf-viewer-page-container {
    max-width: 1000px;
    margin: 0 auto;

    .download {
        position: fixed;
        bottom: 48px;
        left: 50vw;
        transform: translateX(-50%);
        // top: 100px;
        // right: 50px;
        z-index: 9;
        background-color: rgba(255, 255, 255, 1);
        padding: 12px 24px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
        border-radius: 40px;
        font-weight: 500;
        color: rgb(88, 88, 88);
    }

}