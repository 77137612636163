.learning-pathway-page-container {
    max-width: 1000px;
    margin: 0px auto 60px;

    >.query-input input {
        margin-top: 12px;
        max-width: 400px;
    }

    h3.title {
        // max-width: 1000px;
        font-size: 32px;
        font-family: var(--font-title);
        color: hsl(0, 0%, 30%);
    }

    .query-input {
        input {
            width: 400px;
        }
    }

    .learning-pathway-container {
        margin: 24px -8px;

        h4 {
            margin: 0 12px;
            font-size: 20px;
            opacity: 0.7;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 430px) {
    .learning-pathway-page-container {
        .query-input {
            input {
                width: 100%;
            }
        }

        h3.title {
            font-size: 26px;
        }
    }
}