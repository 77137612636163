.banner-container {
    --purple: rgb(127, 67, 250);
    --blue: rgb(67, 67, 250);
    width: 100%;
    background: linear-gradient(to right, var(--blue) 1%, var(--purple) 100%);
    padding: 6px 0;
    text-align: center;

    a {
        font-size: 15px;
        color: white;
        font-weight: 500;

        span {
            color: rgb(255, 217, 0);
            font-weight: 600;
            text-decoration: underline;
            // text-decoration-color: rgba(255, 255, 255, 0.7);
            text-underline-offset: 2px;
        }
    }
}