 .block-container {
     margin: 8px;
     width: calc(100% - 16px); // Subtract double the margin
     display: inline-block;
     border-radius: 15px;
     padding: 20px;
     border: 0.5px solid rgba(0, 0, 0, 0.05);
     box-shadow: 0 0 6px rgba(0, 0, 0, 0.05), 0 0 2px rgba(0, 0, 0, 0.05);

     &:hover {
         box-shadow: 0 0 12px rgba(0, 0, 0, 0.15), 0 0 2px rgba(0, 0, 0, 0.05);
     }

     p,
     span {
         hyphens: auto;
         word-break: normal;
     }

     .description-input.form-txt textarea.field {
         height: 60px;
     }

     .form-txt {
         width: 100%;

         .field {
             width: 100%;
             border-color: hsl(0, 0%, 90%);
             background-color: rgba(255, 255, 255, 0.4);
         }
     }


     .block-title-container {
         display: flex;
         align-items: flex-start;
         justify-content: space-between;
         margin-bottom: 20px;

         .right {
             display: flex;
             gap: 6px;
             align-items: center;

             .icon {
                 font-weight: 200;

                 color: rgb(114, 114, 114);

                 &.delete {
                     color: rgba(161, 8, 8, 0.74);
                 }

                 font-size: 22px;

                 &:hover {
                     cursor: pointer;
                 }
             }


         }

         .title {
             font-weight: 700;
             padding: 4px 0;
             display: flex;
             align-items: center;
             gap: 6px;

             .icon {
                 font-size: 20px;
                 opacity: 0.7;
                 margin-top: 2px;
             }
         }

         .tag {
             font-size: 13px;
             padding: 4px 12px;
             border-radius: 6px;
             font-weight: 500;
             white-space: nowrap;

             &.pdf {
                 color: rgba(178, 122, 25, 0.6);
                 background-color: rgba(220, 150, 11, 0.1);
             }

             &.link {
                 color: rgb(26, 86, 151, 0.6);
                 background-color: rgba(11, 112, 220, 0.05);
             }

             &.text {
                 color: rgba(26, 151, 66, 0.6);
                 background-color: rgba(26, 151, 66, 0.05);
             }

             &.community {
                 color: rgba(26, 43, 151, 0.5);
                 background-color: rgba(26, 43, 151, 0.05);
             }

             &.pathway {
                 color: rgba(151, 30, 26, 0.5);
                 background-color: rgba(151, 30, 26, 0.05);
             }
         }
     }

     .desc {
         line-height: 1.4;
         font-size: 15px;
         margin-bottom: 10px;
     }

     .icon-container {
         display: flex;
         align-items: center;
         gap: 6px;

         &:has(.icon.material-symbols-outlined) {
             align-items: unset;
         }

         .icon {
             height: 20px;
             width: auto;
         }

         .icon.material-symbols-outlined {
             font-size: 16px;
         }

         p.url {
             // word-wrap: break-word;
             // overflow-wrap: break-word;
             font-size: 0.8em;
             display: block;
             white-space: normal;
             word-wrap: break-word;
         }
     }
 }


 .link-block-container {
     background-color: hsl(0, 0%, 99%);
     padding: 0;
     display: inline-grid;
     //  width: calc(100% - 16px); // Subtract double the margin
     grid-template-columns: 1fr;
     overflow: visible;
     position: relative;

     p {
         word-break: break-all;

         &.title {
             word-break: normal;
         }
     }

     &:hover {
         cursor: pointer;
     }

     &.with-img {
         grid-template-columns: 1fr 9fr;
     }

     img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         border-top-left-radius: 15px;
         border-bottom-left-radius: 15px;
     }

     >div {
         padding: 20px;
     }



     .desc {
         display: -webkit-box;
         -webkit-line-clamp: 2;
         -webkit-box-orient: vertical;
         overflow: hidden;
     }

     .img-url-edit-container {
         position: absolute;
         color: white;
         left: -6px;
         top: -6px;
         font-size: 26px;
         text-shadow: 0 0 16px rgba(0, 0, 0, 0.5), 0 0 2px rgba(0, 0, 0, 0.75);
         z-index: 99999999999999;

         .thumbnail-url-input input {
             background-color: white;
             box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
         }
     }
 }

 .text-block-container {
     border: 1px solid hsl(0, 0%, 90%);
     background-color: hsl(0, 0%, 99%);
     box-shadow: none;

     &:hover {
         box-shadow: none;
     }
 }

 .pdf-block-container {
     .desc {
         margin-bottom: 0;
     }

     &:hover {
         cursor: pointer;
     }
 }

 .pathway-block-container {
     &:hover {
         cursor: pointer;
     }
 }

 .action-container {
     height: 28px;
     display: flex;
     align-items: center;

     .confirm-action {
         background-color: hsl(0, 0%, 98%);
         border-radius: 6px;
         display: flex;
         gap: 6px;
         right: 0;
         top: 0;
         padding: 4px;

         button {
             padding: 3px 12px;
             border-radius: 4px;
         }

         .confirm {
             color: rgba(161, 8, 8, 0.74);

             &:hover {
                 cursor: pointer;
                 background-color: rgb(172, 23, 23);
                 color: white;
             }
         }

         .cancel {
             color: rgba(15, 87, 15, 0.806);

             &:hover {
                 cursor: pointer;
                 background-color: rgb(27, 105, 27);
                 color: white;
             }
         }
     }

     .edit-action-container {
         .icon {
             color: hsl(0, 0%, 50%);
         }
     }
 }

 .add-container {
     &:hover {
         box-shadow: none;
     }
 }

 @media screen and (max-width:430px) {

     .right {
         position: relative;

     }

     .delete-container {
         width: 24px;


         .confirm-deletion {
             box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
             border: 0.5px solid rgba(0, 0, 0, 0.1);
             position: absolute;
         }
     }

     .breadcrumb-container {
         .delete-container {
             position: relative;
         }
     }
 }