.privacy-container {
    color: rgb(67, 67, 67);
    margin-top: 60px;
    margin-bottom: 60px;

    h1 {
        font-weight: 500;
    }

    h2 {
        font-weight: 600;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 6px;
    }

    p {
        line-height: 1.5;

        &:first-of-type {
            margin-bottom: 60px;
        }
    }
}