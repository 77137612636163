.upload-btn-inner {
    // margin-top: 36px;
    background-color: hsl(0, 0%, 90%);
    padding: 8px 18px;
    font-size: 16px;
    color: hsl(0, 0%, 45%);
    border-radius: 4px;
    font-weight: 400;

    &:hover {
        cursor: pointer;
        color: white;
        background-color: hsl(0, 0%, 80%);
    }



}

.upload-container {
    max-width: 100%;
    margin: 0px auto;
    color: hsl(0, 0%, 30%);

    &.disabled {

        >div,
        form {
            opacity: 0.6;
        }
    }

    .types-container {
        align-items: center;
        gap: 16px;
        margin: 48px 0 24px;

        p {
            font-size: 17px;
        }



        .options-container {
            background-color: hsl(0, 0%, 95%);
            border-radius: 6px;
            padding: 4px;

            button {
                padding: 4px 16px;
                font-size: 16px;
                border-radius: 4px;

                &:hover {
                    cursor: pointer;
                }

                &.selected {
                    background-color: white;
                    box-shadow: 0 0 6px rgba(0, 0, 0, 0.07);
                }
            }
        }
    }

    form {
        margin: 0;

        .form-txt {
            textarea.field {
                height: 60px;
            }

            &.url {
                textarea.field {
                    height: 30px;
                }
            }
        }

        .form-select {
            flex-direction: column;
        }
    }

    h1 {
        font-weight: 500;
        color: hsl(0, 0%, 30%);
        font-size: 26px;
    }

    // form {

    //     button {
    //         margin-top: 36px;
    //         margin-left: 100%;
    //         transform: translateX(-100%);
    //         background-color: hsl(0, 0%, 90%);
    //         padding: 8px 18px;
    //         font-size: 16px;
    //         color: hsl(0, 0%, 45%);
    //         border-radius: 4px;
    //         font-weight: 400;


    //         &:hover {
    //             cursor: pointer;
    //             color: white;
    //             background-color: hsl(0, 0%, 80%);
    //         }
    //     }
    // }

    .title {}

    .desc {}

    .subject {}

    .tags {}

    .add-new {
        width: 100%;
        gap: 8px;
        margin: 8px 0 24px;
        display: grid;
        grid-template-columns: auto 1fr;
    }
}