* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.App {
  --font-title: "Kanit", sans-serif;
  --dark-gray: hsl(0, 0%, 30%);
}

main {
  margin-top: 93px;
  /* margin-top: 63px; */
  min-height: 100vh;
}

main.challenge {
  margin-top: 0;
}

.content {
  padding: 48px;
  flex-grow: 1;
}

.nav-placeholder {
  width: 261px;
  flex-shrink: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a.dec {
  text-decoration: underline;
}

ul {
  list-style: none;
}

p,
span,
i {
  font-size: inherit;
  color: inherit;
  font-family: inherit;
}

button {
  border: none;
  background-color: transparent;
}

.flex {
  display: flex;
}

.block {
  display: block;
}

.logo {
  font-family: var(--font-title);
  font-size: 24px;
}

.h-title {
  font-family: var(--font-title);
  color: hsl(0, 0%, 30%);
  margin-bottom: 12px;
}

.cl-dark-gray {
  color: var(--dark-gray);
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

@media screen and (max-width: 430px) {
  .content {
    padding: 30px;
  }

  .content:has(.pdf-viewer-page-container) {
    padding: 0;
  }

  .title-center {
    text-align: center;
  }
}

.negative-margin {
  margin: 0 -6px;
}
