.nav-container {
    padding: 30px;
    position: fixed;
    top: 86px;
    // top: 55px;
    height: calc(100vh - 86px);
    // height: calc(100vh - 55px);
    background-color: white;
    z-index: 9999;
    border-right: 1px solid hsl(0, 0%, 90%);
    overflow: scroll;

    &.closed {
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
    }

    &.open {
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
    }

    .community-title {
        display: flex;
        align-items: center;
        gap: 12px;

        button {
            border: 1px solid hsl(0, 0%, 60%);
            padding: 2px 12px 4px 8px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: hsl(0, 0%, 25%);

            &:hover {
                outline: 1px hsl(0, 0%, 60%) solid;
                font-weight: 500;
                cursor: pointer;

                .icon {
                    font-weight: 500;
                }
            }

            .icon {
                font-size: 16px;
                padding-top: 2px;
                font-weight: 300;
            }
        }

    }

    form.create-community {
        border-bottom: 1px solid hsl(0, 0%, 92%);
        padding-bottom: 24px;
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;

        input {
            width: 100%;
            display: block;
        }

        button {
            // border: 1px solid hsl(0, 0%, 60%);
            background-color: hsl(0, 0%, 90%);
            color: hsl(0, 0%, 40%);
            padding: 6px 12px;
            border-radius: 6px;
            margin-top: 6px;
            font-weight: 600;

            &:hover {
                cursor: pointer;
                background-color: hsl(0, 0%, 85%);
            }
        }
    }

    .item {
        align-items: flex-start;
        gap: 6px;
        color: hsl(0, 0%, 25%);
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 12px;
        width: 200px;

        &.selected {
            font-weight: 800;
        }

        .icon {
            font-weight: 200;
            color: hsl(0, 0%, 60%);
        }


        &:hover {
            cursor: pointer;
        }
    }

    .divider {
        margin-bottom: 30px;
        border-bottom: 1px solid hsl(0, 0%, 90%);
        padding-bottom: 20px;
    }

    .block {
        margin-top: 12px;
        margin-bottom: 48px;
        display: flex;
        flex-direction: column;
        gap: 12px;

    }

    p {
        margin-bottom: 24px;
        font-weight: 700;
        color: hsl(0, 0%, 70%);
        font-size: 14px;
    }

}

@media screen and (max-width: 430px) {
    .nav-container {
        padding: 30px 30px 120px 30px;
        width: 100vw;
        overflow: scroll;
        flex-direction: column;

        .item {
            width: 100%;
        }
    }
}