.chatbot-container {
    background-color: #fff;
    position: fixed;
    bottom: 16px;
    right: 16px;
    padding: 16px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    font-size: 16px;

    z-index: 999999999999;

    button {
        font-size: inherit;
        display: flex;
        align-items: center;
        gap: 3px;

        span {
            font-size: 18px;
            opacity: 0.8;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .chatbot-history {
        margin-right: 16px;
        border-right: #eee solid 1px;
        padding-right: 16px;
        max-height: calc(100vh - 300px);
        overflow-y: scroll;

        .history-session {
            margin: 6px 0;
            gap: 6px;
            justify-content: space-between;

            &:hover {
                cursor: pointer;
            }

            button {
                opacity: 0.5;

                span {
                    font-weight: 200;
                    font-size: 20px;
                }
            }
        }
    }

    .chatbot-chat {
        // min-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        justify-content: space-between;
        max-height: calc(100vh - 300px);

        .btn-new-mid {
            // height: 100%;
            margin: auto 0;
            padding: 8px 16px;
            border-radius: 8px;
            color: white;
            background: linear-gradient(to top right, rgba(67, 67, 250, 0.9) 1%, rgba(128, 67, 250, 0.9) 100%);
        }

        .chatbot-nav {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            button.btn-history {
                opacity: 0.6;
            }

            button.btn-new {
                color: rgba(128, 67, 250);
                margin-left: 6px;
                border-left: 1px solid #eee;
                padding-left: 6px;
                white-space: nowrap;

                &:hover {
                    color: rgb(88, 23, 218);
                }
            }

            button.btn-hide {
                opacity: 0.4;

                &:hover {
                    opacity: 0.6;
                }

                span {
                    font-size: 22px;
                    font-weight: 300;
                }
            }


            .input-session-name-p {
                padding: 6.5px 8px;
            }

            .input-session-name {
                padding: 6px 8px;
                border: solid 1px #eee;
                border-radius: 6px;
                font-size: 14px;
                margin: 0 12px;
                width: 150px;
            }
        }

        .chatbot-conversation {
            min-height: 30px;
            max-width: 400px;
            height: 100%;
            overflow-y: scroll;
            margin: 0px auto 16px 0;

            >div {
                display: flex;
                gap: 6px;

                p {
                    margin: 12px 0;
                    line-height: 1.2;
                    color: #333;
                }
            }
        }

        .chatbot-prompt {
            display: flex;
            gap: 8px;

            input,
            button {
                border-radius: 6px;
            }

            input {
                border: 1px solid hsl(0, 0%, 80%);
                min-width: 300px;
                width: 100%;
                padding: 6px;
            }

            button {
                background: linear-gradient(to top right, rgba(67, 67, 250, 0.9) 1%, rgba(128, 67, 250, 0.9) 100%);
                // background-color: rgba(128, 67, 250, 0.718);
                padding: 8px 12px;
                color: #fff;
                font-weight: 500;
                font-size: inherit;

                &:hover {
                    background: linear-gradient(to top right, rgba(67, 67, 250) 1%, rgba(128, 67, 250) 100%);
                    // background-color: rgba(128, 67, 250);
                }
            }
        }
    }

    .chatbot-close {
        button {
            color: rgba(128, 67, 250);
            font-weight: 600;

            span {
                font-weight: 500;
                margin-right: 6px;
            }
        }
    }


}

@media screen and (max-width: 480px) {
    .chatbot-container {
        width: calc(100vw - 32px);
        max-width: unset;


        &:has(.chatbot-close) {
            width: auto;
        }

        &:has(.chatbot-history) {
            min-height: 50vh;
        }

        .chatbot-open {
            width: 100%;
        }

        .chatbot-chat {
            max-height: calc(100vh - 160px);
        }


        .chatbot-chat {
            width: 100%;

            .chatbot-conversation {
                margin-bottom: 0;
            }

            .chatbot-nav .input-session-name {
                width: 80%;
            }

            .chatbot-prompt {
                width: 100%;

                input {
                    min-width: unset;
                }
            }
        }

        .chatbot-history {
            height: 100%;
            max-height: unset;
            min-width: 50vw;
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 16px;
            overflow-y: scroll;
            background-color: white;
            border-bottom-left-radius: 16px;
            border-top-left-radius: 16px;

            z-index: 9999999999999;
        }
    }

}