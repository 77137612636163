.success-container {
    position: fixed;
    top: 77px;
    right: 18px;
    background-color: rgb(238, 243, 249);
    border: 1.5px solid rgb(173, 198, 227);
    padding: 6px 12px;
    color: rgb(95, 135, 181);
    border-radius: 8px;
    font-weight: 500;
    transition: transform 0.25s ease-in-out;
}

.success-container.slide-out {
    transform: translateX(calc(100% + 18px));
}