.breadcrumb-container {
    display: flex;
    justify-content: space-between;
    margin: -30px;
    align-items: center;

    p.breadcrumb {
        color: hsl(0, 0%, 70%);
        font-size: 16px;

        .current {
            color: hsl(0, 0%, 30%);
        }
    }

    .icon {
        color: rgba(161, 8, 8, 0.74);
        font-weight: 300;
        margin-right: 12px;

        &:hover {
            cursor: pointer;
        }
    }



}

.block-container.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 36px 0;
    border: 1px solid hsl(0, 0%, 85%);
    box-shadow: none;



    .post-btn {
        opacity: 0.5;
        display: flex;
        align-items: center;
        font-size: 20px;
        gap: 2px;
        width: 100%;

        &:hover {
            cursor: pointer;
        }

        .icon {
            font-size: 26px;
            font-weight: 200;
        }
    }
}

.post-container.inner {
    background-color: white;
    z-index: 999;
    width: 100%;


    h1 {
        font-weight: 500;
        color: hsl(0, 0%, 30%);
        font-size: 26px;
    }

    .create-input {
        display: flex;
        gap: 6px;

        p {
            color: hsl(0, 0%, 30%);
        }
    }

    form {
        margin: 24px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100000px;

        >div {
            width: 100%;

            &.flex-row {
                display: grid;
                grid-template-columns: 1fr 2fr;
                gap: 6px;
                flex-grow: 1;
            }
        }

        .upload-btn-inner.c {
            margin-top: 12px;
        }

    }


    .cancel {
        position: absolute;
        right: 28px;
        top: 28px;
        opacity: 0.5;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

}

.pathway-container {
    margin-top: 20px;
    border-radius: 6px;
    max-width: 1000px;
    margin: 20px auto;


    &.breadcrumb {
        margin-top: 80px;
        padding: 12px;

        .top {
            h4 {
                font-size: 26px;
                font-weight: 600;
            }


        }

        .blocks-container {
            margin: 36px -8px 0;
        }
    }



    .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 48px;

        .mid {
            display: flex;
            align-items: flex-start;
            gap: 12px;
            flex-grow: 1;
            width: 100%;

            >div {
                width: 100%;

                textarea.field {
                    height: 60px;
                }
            }
        }

        h4 {
            font-size: 20px;
            font-weight: 500;
            display: inline-flex;
            align-items: center;
            position: relative;

            .icon {
                margin-top: 5px;
            }

            // &::before {
            //     content: "";
            //     display: block;
            //     position: absolute;
            //     bottom: 1px;
            //     left: 4px;
            //     width: 100%;
            //     height: 14px;
            //     z-index: -1;
            //     background-color: rgba(255, 136, 0, 0.3);
            // }
        }

        .desc {
            font-style: italic;
            margin-top: 6px;
        }
    }








    .blocks-container {
        column-count: 1;
        column-gap: 0;
        break-inside: avoid;
        margin: 16px -8px 0;

        @media (min-width: 1250px) {
            column-count: 1;
        }
    }

    .filter-container {
        margin-bottom: 24px;

        span {
            border-radius: 6px;
            margin-right: 6px;
            padding: 4px 16px;
            font-size: 12px;
            font-weight: 600;

            &:hover {
                cursor: pointer;
            }

            &.pdf {
                color: rgba(178, 122, 25, 0.6);
                border: 1px solid rgba(178, 122, 25, 0.6);
                background-color: rgba(220, 150, 11, 0.1);
            }

            &.link {
                color: rgb(26, 86, 151, 0.6);
                border: 1px solid rgb(26, 86, 151, 0.6);
                background-color: rgba(11, 112, 220, 0.05);
            }

            &.text {
                color: rgba(26, 151, 66, 0.6);
                border: 1px solid rgba(26, 151, 66, 0.6);
                background-color: rgba(26, 151, 66, 0.05);
            }

            &.on {
                // background-color: hsl(0, 0%, 90%);
            }

            &.off {
                background-color: white !important;
            }


        }
    }





}

@media screen and (max-width: 430px) {
    .breadcrumb-container {
        margin: 0px;

        .icon {
            margin-right: 0;
        }
    }

    .pathway-container.breadcrumb {
        margin-top: 24px;
        padding: 0;

        .top {
            flex-direction: column;
            gap: 24px;


        }


        .blocks-container {
            margin-top: 12px;
        }

        .types-container {
            flex-direction: column;
            align-items: self-start;
            margin-top: 24px;
        }
    }
}