.form-item {
    .field {
        margin-bottom: 8px;

        &:hover {
            cursor: text;
        }
    }


    select {
        &:disabled {
            cursor: not-allowed !important;
        }
    }

    textarea {
        &:disabled {
            cursor: not-allowed !important;
        }

        &::placeholder {
            /* Most modern browsers support this */
            color: lightgray;
            opacity: 1;
            /* Firefox needs this to change color */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: lightgray;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: lightgray;
        }
    }

    input {
        &:disabled {
            cursor: not-allowed !important;
        }


        &::placeholder {
            /* Most modern browsers support this */
            color: lightgray;
            opacity: 1;
            /* Firefox needs this to change color */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: lightgray;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: lightgray;
        }
    }

    p {
        font-family: inherit;
        line-height: 30px;
        padding: 0 5px;
        display: inline-block;
        font-size: 17px;
    }

    img {
        height: 15px;
        position: absolute;
        left: 10px;
        top: 37px;
    }
}

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
.form-txt {
    position: relative;
    font-family: sans-serif;

    &:hover {
        .field {
            border-color: hsl(0%, 0%, 40%);
        }
    }


    &.url {
        textarea.field {
            height: 60px;
        }
    }

    .field {
        font-family: inherit;
        background-color: inherit;
        border: 1px solid hsl(0%, 0%, 80%);
        width: 100%;
        height: 30px;
        border-radius: 5px;
        padding: 6px 12px;
        font-size: 16px;

        &:has(img) {
            padding-left: 30px;
        }

        &:focus {
            border-color: hsl(0%, 0%, 40%);
            outline: none;
        }


    }

    textarea.field {
        height: 180px;
        resize: none;
    }
}

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////



.form-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;

    input[type="checkbox"] {
        display: none;
    }

    svg {
        width: 14px;
        height: 14px;
    }

    p {
        font-family: sans-serif;
        font-size: 15px;
        color: hsl(0%, 0%, 20%)
    }

    &:hover {
        cursor: pointer;
    }
}




////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////



.form-select {
    position: relative;
    font-family: sans-serif;
    display: flex;
    gap: 8px;

    &:hover {

        select {
            // border-width: 2px;
        }
    }

    select {
        font-size: 16px;
        color: #5b5b5b;
        background-color: white;
        border: 1px solid #ccc;
        padding: 5px 10px;
        appearance: none;
        -webkit-appearance: none;
        background-image: url("../../assets/media/icon/unfold.svg");
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 25px;
        flex-grow: 1;

        &:focus {
            // border-width: 2px;
            outline: none;
        }

        option {
            padding-left: 30px;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    // -webkit-text-fill-color: #E2C5A3 !important;
}