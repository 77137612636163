 .fill {
     background-color: hsl(0, 0%, 96%);
     border-radius: 4px;
 }

 .community-skeleton-loader-page-container {
     margin-bottom: 126px;
     overflow: hidden;
     position: relative;

     &::before {
         content: "";
         position: absolute;
         top: 0;
         left: -100%;
         width: 20%;
         height: 100%;
         transform: rotate(12deg);
         background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
         animation: shine 2.5s infinite;
     }



     .community-skeleton-loader-container {
         max-width: 1000px;
         margin: 0 auto;
         height: auto;

         .title-container {
             display: flex;
             align-items: center;
             gap: 20px;
             padding-bottom: 8px;
             margin-bottom: 24px;

             &.home {
                 margin-bottom: 8px;
                 border-bottom: 1px solid hsl(0, 0%, 95%);
             }

             .title {
                 display: inline-block;
                 height: 32px;
                 width: 30%;
                 background-color: hsl(0, 0%, 94%);
             }

             .tag {
                 height: 24px;
                 width: 10%;
                 border-radius: 500px;
             }
         }


         .pathway-skeleton-loader-container {
             margin-bottom: 48px;



             .path {
                 height: 24px;
                 width: 40%;
             }

             .desc-container {
                 display: flex;
                 align-items: center;
                 gap: 16px;
                 margin-top: 8px;
                 margin-bottom: 20px;

                 .line {
                     display: inline-block;
                     width: 30px;
                     height: 1px;
                 }

                 .desc {
                     display: inline-block;
                     height: 20px;
                     width: 50%;
                     opacity: 0.8;
                 }
             }

             .block {
                 width: 100%;
                 margin-bottom: 12px;
                 border-radius: 15px;
                 background-color: hsl(0, 0%, 98%);

                 &.l {
                     height: 48px;
                 }

                 &.m {
                     height: 72px;
                 }

                 &.s {
                     height: 144px;
                 }
             }
         }


     }


     @keyframes shine {
         to {
             left: 100%;
         }
     }
 }