.upload-page-container {
    max-width: 600px;
    margin: 0px auto;
    color: hsl(0, 0%, 30%);

    .upload-btn-inner {
        margin-left: 100%;
        margin-top: 24px;
        transform: translateX(-100%);

        @media screen and (max-width: 430px) {
            margin-left: 0;
            transform: translateX(0);
            width: 100%;
        }
    }
}