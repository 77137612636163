.search-result-page-container {
    max-width: 1000px;
    margin: 0 auto;

    h1 {
        margin-bottom: 36px;

        .query {
            font-style: italic;
        }
    }
}