.community-container {
    max-width: 1000px;
    margin: 0 auto;

    .title-container {
        align-items: center;
        gap: 14px;
        margin: 0 0px 16px;
        padding-bottom: 4px;
        border-bottom: 1px solid hsl(0, 0%, 90%);

        >h2 {
            font-family: 'Courier New', Courier, monospace;
            font-family: var(--font-title);
        }

        >p {
            background-color: hsl(206, 83%, 88%);
            padding: 3px 14px 4px;
            border-radius: 40px;
            font-size: 13px;
            font-weight: 600;
            color: hsl(206, 63%, 34%);
        }
    }

    .more {
        background-color: hsl(0, 0%, 95%);
        padding: 8px 18px;
        border-radius: 50px;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin: 48px auto 120px;
    }
}