.careers-challenge-page-container {
    margin: -48px;
    padding: 24px 48px;
    color: rgb(255, 255, 255, var(--opacity));
    --dark-blue: rgb(13, 18, 36);
    --purple: rgb(127, 67, 250);
    --blue: rgb(67, 67, 250);
    --opacity: 0.8;
    background-color: rgb(13, 18, 36);
    border-bottom: solid rgb(127, 67, 250, 0.5) 0.5px;
    position: relative;


    .blur {
        width: 70vw;
        height: 50vh;
        position: absolute;
        top: -50px;
        left: 30vw;
        border-radius: 50%;
        /* Makes the div circular */
        background: linear-gradient(to right, blue, purple);
        filter: blur(100px);
        opacity: 0.3;
    }

    .blur2 {
        top: 80vh;
        left: -10vw;
        opacity: 0.2;
    }

    h2 {
        color: white;
        text-align: center;
        margin-bottom: 60px;
        font-size: 28px;
    }


    .header {
        position: relative;
        z-index: 9999;
        padding: 16px;
        align-items: center;
        justify-content: space-between;

        .logo {
            // color: var(--blue);
            font-size: 26px;
            background: linear-gradient(to right, var(--blue) 1%, var(--purple) 100%);
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
        }

        .list {
            align-items: center;
            gap: 36px;

            li {
                color: rgb(255, 255, 255, var(--opacity));

                &:hover {
                    color: white;
                    cursor: pointer;
                }

                &.application {
                    background-color: var(--purple);
                    border-radius: 50px;
                    padding: 8px 16px;
                    color: white;
                    font-weight: 600;

                    &:hover {
                        background-color: rgb(110, 45, 239);
                    }
                }
            }
        }
    }

    .intro-container {
        height: calc(100vh - 60px);
        // height: 80vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 36px;
        padding-bottom: 60px;

        h1 {
            color: white;
            text-align: center;

            span {
                background: linear-gradient(to right, var(--blue) 1%, var(--purple) 100%);
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
            }
        }

        p {
            text-align: center;
            max-width: 700px;
            line-height: 1.5;
            font-size: 17px;
        }

        a {
            background-color: var(--purple);
            background: linear-gradient(to top right, var(--blue) 1%, var(--purple) 100%);
            padding: 16px 32px;
            border-radius: 50px;
            font-weight: 600;
            color: white;
            font-size: 24px;
            margin-top: 10vh;
            text-align: center;

            &:hover {
                cursor: pointer;
                background: linear-gradient(to bottom left, var(--blue) 1%, var(--purple) 100%);
            }
        }
    }

    .challenge-content {
        flex-direction: column;
        align-items: center;

        .section {
            margin-bottom: 120px;
            padding-top: 120px;

            &:last-of-type {
                margin-bottom: 180px;
            }
        }

        .challenges-container {
            ul {
                display: grid;
                grid-template-columns: repeat(3, 33.3%);
                gap: 16px;

                li {
                    position: relative;
                    box-shadow: 0 0 0 1.5px rgba(255, 255, 255, 0.15);
                    background-color: rgba(255, 255, 255, 0.05);
                    border-radius: 16px;
                    padding: 36px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 16px;
                    text-align: center;
                    // overflow: hidden;
                    // border: solid rgba(255, 255, 255, 0.095) 1px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: -1.5px;
                        /* Align to the top edge of the box */
                        left: 10%;
                        width: 80%;
                        height: 1.5px;
                        // background: linear-gradient(to left, purple 15%, blue 85%);
                        background: linear-gradient(to left, rgba(255, 255, 255, 0) 15%, var(--blue) 45%, var(--purple) 70%, rgba(255, 255, 255, 0) 85%);
                    }



                    h3 {
                        color: white;
                        font-size: 22px;
                    }

                    p {
                        text-align: center;

                        span {
                            font-size: 60px;
                            font-weight: 100;
                            background: linear-gradient(to right, var(--blue) 1%, var(--purple) 100%);
                            -webkit-background-clip: text;
                            background-clip: text;
                            color: transparent;
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        .timeline-container {
            // text-align: center;
            position: relative;

            .blur {
                left: 50vw;
                top: 20vw;
                filter: blur(120px);
            }

            ul {
                margin-left: 36px;

                >div {
                    // border-left: solid 2px blue;
                    padding-left: 24px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        width: 2px;
                        opacity: 0.6;
                        // background: linear-gradient(to left, rgba(255, 255, 255, 0) 15%, var(--blue) 45%, var(--purple) 70%, rgba(255, 255, 255, 0) 85%);
                        background: linear-gradient(to right, var(--blue) 1%, var(--purple) 100%);
                    }
                }

                h3 {
                    margin: 36px 0 16px;
                    color: white;

                    span {
                        font-weight: 400;
                    }
                }

                li {
                    margin-bottom: 12px;

                    &.submit {
                        display: flex;
                        justify-content: space-between;

                        a {
                            background-color: var(--purple);
                            padding: 3px 8px;
                            border-radius: 2px;
                            font-size: 14px;
                            font-weight: 600;
                            color: rgba(255, 255, 255, 0.7);
                            cursor: not-allowed;
                        }
                    }

                    p {
                        margin-top: 4px;
                    }
                }
            }
        }

        .submission-container {

            a,
            p {
                background: linear-gradient(to top right, var(--blue) 1%, var(--purple) 100%);
                padding: 16px 32px;
                border-radius: 50px;
                font-weight: 600;

                &:hover {
                    color: white;
                }
            }
        }
    }


}


@media screen and (max-width: 760px) {
    .content:has(.careers-challenge-page-container) {
        padding: 0;
    }

    .careers-challenge-page-container {
        width: 100vw;
        margin: 0;

        .header {
            flex-direction: column;
            padding: 16px 0;

            .list {
                margin-top: 12px;

                li {
                    text-align: center;
                }
            }
        }

        .intro-container {
            height: auto;

            h1 {
                margin-top: 60px;
            }
        }

        .challenge-content {

            .challenges-container {
                ul {
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 16px;
                }
            }

            .timeline-container {
                .blur {
                    display: none;
                }

                ul {
                    margin-left: 0;
                }
            }
        }
    }
}